<template>
  <div
    class="ques_content"
    v-loading="loading"
    element-loading-text="加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(183,208,208,0.8)"
  >
    <!-- 问卷标题 -->
    <div class="ques_content_title">{{title}}</div>
    <!-- 问卷题目 -->
    <div class="ques_content_main" v-for="(v,k,i) in listData" :key="i">
      <div class="type">
        {{i |filterNum}}、{{k|filterType}}（共
        <span class="type_item">{{allData.num[k]}}</span>
        题，共
        <span class="type_item">{{allData.type[k]}}</span>分，每题
        <span class="type_item">{{ allData.type[k]/allData.num[k]}}</span>分）
      </div>
      <!-- 单选题 -->
      <div v-if="k==1">
        <div class="subject" v-for="(item,key) in v" :key="key">
          <div class="subject_title">{{key+1}}、 {{item.title}}</div>
          <div class="parameter">
            <span
              v-for="(item2,key2) in item.parameter.split('\n')"
              :key="key2"
              class="parameter_item"
            >{{item2}}</span>
          </div>
          <p>
            答：
            <span>
              <!-- <el-input v-model="answer[item.id]" placeholder size="mini"></el-input> -->
              <input v-model="answer[item.id]" />
            </span>
          </p>
        </div>
      </div>
      <!-- 多选题 -->
      <div v-if="k==2">
        <div class="subject" v-for="(item,key) in v" :key="key">
          <div class="subject_title">{{key+1}}、 {{item.title}}</div>
          <div class="parameter">
            <span
              v-for="(item2,key) in item.parameter.split('\n')"
              :key="key"
              class="parameter_item"
            >{{item2}}</span>
          </div>
          <p>
            答：
            <span>
              <input v-model="answer[item.id]" />
            </span>
          </p>
        </div>
      </div>
      <!-- 判断题 -->
      <div v-if="k==3">
        <div class="subject" v-for="(item,key) in v" :key="key">
          <div class="subject_title">{{key+1}}、 {{item.title}}</div>
          <div class="parameter">
            <!-- <el-radio v-model="answer[item.id]" label="1">对</el-radio>
            <el-radio v-model="answer[item.id]" label="0">错</el-radio>-->

            <label :for="item.id">
              <input type="radio" :id="item.id" value="11" v-model="answer[item.id]" />对
            </label>

            <label :for="item.id+1">
              <input type="radio" :id="item.id+1" value="00" v-model="answer[item.id]" />错
            </label>
          </div>
        </div>
      </div>
      <!-- 填空题 -->
      <div v-if="k==4">
        <div class="subject" v-for="(item,key) in v" :key="key" id="blank" :data-id="item.id">
          <!-- <div class="subject_title">{{key+1}}、 {{item.title | filteBlank}}</div> -->
          <div
            class="subject_title blank"
            v-html="`${key+1}、`+$options.filters.filteBlank(item.title)"
          ></div>
        </div>
      </div>
      <!-- 排序题 -->
      <div v-if="k==5">
        <div class="subject" v-for="(item,key) in v" :key="key">
          <div class="subject_title">{{key+1}}、 {{item.title}}</div>
          <div class="parameter">
            <span
              v-for="(item2,key) in item.parameter.split('\n')"
              :key="key"
              class="parameter_item"
            >{{item2}}</span>
          </div>
          <p>
            答：
            <span>
              <!-- <el-input v-model="answer[item.id]" placeholder="请按顺序填写即可" size="mini"></el-input> -->
              <input v-model="answer[item.id]" />
            </span>
          </p>
        </div>
      </div>
      <!-- 计算题 -->
      <div v-if="k==6">
        <div class="subject" v-for="(item,key) in v" :key="key">
          <div class="subject_title">{{key+1}}、 {{item.title}}</div>
          <div class="parameter">
            <p>
              答：
              <span>
                <input v-model="answer[item.id]" />
              </span>
            </p>
          </div>
        </div>
      </div>
      <!-- 解答题、问答题、作文题 -->
      <div v-if="k==7 || k==8 ||k==9">
        <div class="subject" v-for="(item,key) in v" :key="key">
          <div class="subject_title">{{key+1}}、 {{item.title}}</div>
          <div class="parameter">
            <!-- <p>
              答：
              <span>
                <el-input
                  type="textarea"
                  :rows="3"
                  v-model="answer[item.id]"
                  placeholder
                  size="mini"
                ></el-input>

              </span>
            </p>-->
            <p>答：</p>
            <textarea rows="5" v-model="answer[item.id]"></textarea>
          </div>
        </div>
      </div>
    </div>

    <div class="subtn" v-show="!loading">
      <div class="btn" @click="submit">提交</div>
    </div>
  </div>
</template>
 <script>
import { setQsubject } from "@/api/questionnaire.js";
import { log } from "util";
import { userGetQuesubject, putanswer } from "@/api/answer.js";
export default {
  data() {
    return {
      listData: {},
      answer: {},
      allData: {},
      title: "",
      loading: true,
      answerarr: {}
    };
  },
  created() {
    // 获取问卷ID
    let qsid = this.$route.query.qsid;
    let data = {
      id: qsid
    };
    // 渲染题目
    userGetQuesubject(data).then(res => {
      // 2-数据分类处理
      let obj = {};
      res.data.questionnaire.forEach((v, k) => {
        let key = v.type;
        if (obj.hasOwnProperty(key)) {
          obj[key].push(v);
        } else {
          obj[key] = [];
          obj[key].push(v);
        }
      });
      // 1-数据处理
      this.allData = res.data;
      this.listData = obj;
      this.title = this.allData.data.title;
      this.loading = false;
    });
  },
  methods: {
    // 提交问卷
    submit() {
      // 处理填空题数据
      let f = document.querySelectorAll(".subject .user_answer");
      let obj = {};
      f.forEach((v, k) => {
        let id = v.parentNode.parentNode.parentNode.getAttribute("data-id");
        let answer = v.value;
        if (obj.hasOwnProperty(id)) {
          obj[id] += "," + answer;
        } else {
          obj[id] = "";
          obj[id] = answer;
        }
      });
      // 数据合并
      let newObj = {};
      Object.assign(newObj, this.answer, obj);
      this.answerarr = newObj;
      // 请求数据包装
      let data = {
        id: this.$route.query.qsid,
        answerarr: newObj
      };
      putanswer(data).then(res => {
        if (res.code == 400200) {
          this.$message({
            showClose: true,
            duration: 1500,
            message: "恭喜你，提交成功",
            type: "success"
          });
          this.$router.push(`/answer_content/answer_ques`);
        }
      });
    }
  },
  filters: {
    filterType(val) {
      let arr = [
        "单选题",
        "多选题",
        "判断题",
        "填空题",
        "排序题",
        "计算题",
        "解答题",
        "问答题",
        "作文题"
      ];
      return arr[val - 1];
    },
    filterNum(val) {
      let arr = [
        "一",
        "二",
        "三",
        "四",
        "五",
        "六",
        "七",
        "八",
        "九",
        "十",
        "十一"
      ];
      return arr[val];
    },
    filteBlank(val) {
      let valdata = val.split();
      ("你有()个爸爸,有()个妈妈");
      return val.replace(
        /\(\)/g,
        `<span>
      <input type="text" class="user_answer">
      </span>`
      );
    }
  }
};
</script>
 <style lang="less">
.ques_content {
  width: 100%;
  min-width: 320px;
  max-width: 812px;
  // min-height: 200px;
  margin: 0 auto;
  padding: 10px;
  //   background: rgb(221, 220, 220);
  box-sizing: border-box;
  .ques_content_title {
    font-weight: 700;
    font-size: 25px;
    text-align: center;
    margin-bottom: 20px;
  }
  .ques_content_main {
    .type {
      padding: 5px;
      background: rgb(217, 242, 247);
      border-radius: 10px;
      margin: 5px 0;
      color: rgb(16, 42, 121);
      font-size: 18px;
      .type_item {
        color: red;
      }
    }
    .subject {
      margin: 20px 0;
      p {
        line-height: 20px;
      }
      .subject_title {
        font-weight: 700;
      }
      .parameter {
        margin: 10px 0;
        .parameter_item {
          margin-right: 30px;
        }
      }
      label:nth-child(1) {
        margin-right: 50px;
      }
    }
  }
  // 加载等待框
  .el-loading-mask {
    .el-loading-spinner {
      .el-icon-loading {
        color: rgb(34, 77, 85);
      }
      .el-loading-text {
        color: rgb(34, 77, 85);
        font-weight: 700;
      }
    }
  }
  // 表单
  input {
    outline-color: rgb(35, 134, 226);
    padding: 0 5px;
    height: 20px;
  }
  input[type="text"] {
    width: 60px;
  }
  input[type="radio"] {
    height: unset;
    margin-right: 10px;
    background: turquoise;
  }
  textarea {
    width: 95%;
    outline-color: rgb(35, 134, 226);
    padding: 0 5px;
  }
  .subtn {
    display: flex;
    justify-content: center;
    .btn {
      width: 100px;
      height: 50px;
      background: rgb(53, 152, 233);
      text-align: center;
      line-height: 50px;
      font-size: 20px;
      // cursor: pointer;
      border-radius: 20px;
      color: white;
    }
  }
}

@media screen and (max-width: 768px) {
  .ques_content {
    overflow: hidden;
    // width: 100%;
    min-width: 320px;
    max-width: 768px;
    margin: 0 auto;
    // padding: 10px;
    //   background: rgb(221, 220, 220);
    box-sizing: border-box;
    .ques_content_title {
      font-weight: 700;
      font-size: 22px;
      text-align: center;
      margin-bottom: 20px;
    }
    .ques_content_main {
      .type {
        // padding: 5px;
        background: rgb(217, 242, 247);
        border-radius: 10px;
        margin: 5px 0;
        color: rgb(16, 42, 121);
        font-size: 15px;
        .type_item {
          color: red;
        }
      }
      .subject {
        margin: 10px 0;
        .subject_title {
          font-weight: 700;
          font-size: 14px;
        }
        .parameter {
          margin: 10px 0;

          .parameter_item {
            font-size: 14px;
            margin-right: 0px;
            padding-right: 15px;
          }
        }
      }
    }
  }
}
</style>